import React from "react"

import FeaturedImg from "./contactFeaturedImg"
import pages from "../../data/pages"
import Breadcrumbs from "../../components/breadcrumbs"
import Layout from "../../components/layout"
import SideNav from "../../components/sidenav"
import Container from 'react-bootstrap/Container'
import Button from './submitButton'
import Info from './info'

export default () => {

  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [subject] = React.useState("Form submission from MidMichigan Ag Service Contact Us Selling form")
  const [submitted, setSubmitted] = React.useState(false)

  const sendEmail = (e) => {

    e.preventDefault();

    const encode = (data) => {
      return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "selling",
        subject,
        name,
        email,
        phone,
        message
      })
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error));

  }

  return <Layout id="about-us-page" className="secondary-page">
    <Breadcrumbs currentPath={[pages[1], pages[1].children[3]]}></Breadcrumbs>
    <div className="content-sidebar-wrapper">
      <SideNav header={pages[1].title} menu={pages[1].children}></SideNav>
      <Container className="main-content-left">
        <FeaturedImg title={pages[1].children[3].title} ></FeaturedImg>
        <section className="form-wrapper">

          {submitted ? <p className="form-feedback">We recieve your message! A member of our team will touch base with you soon. Thank you!</p> :<form
            name="selling"
            // netlify
            data-netlify="true"
            netlify-honeypot="bot-field"
            className=""
            onSubmit={sendEmail}
          >
            <div>
              <input type="hidden" name="subject" aria-label="subject" value={subject}></input>
            </div>

            <div style={{ display: 'none' }}>
              <label htmlFor="inputFirstName">First Name</label>
              <input type="text" id="inputFirstName" name="bot-field" aria-label="name"></input>
            </div>

            <div className="form-group">
              <label htmlFor="inputName">Name</label>
              <input
                className="form-control"
                type="text"
                id="inputName"
                aria-label="inputName"
                name="name"
                value={name}
                onChange={(e) => {
                  // console.log(e.currentTarget.value)
                  setName(e.currentTarget.value)
                }}
                required
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="inputEmail">Email</label>
              <input
                className="form-control"
                type="email"
                id="inputEmail"
                aria-label="inputEmail"
                name="email"
                value={email}
                onChange={(e) => {
                  // console.log(e.currentTarget.value)
                  setEmail(e.currentTarget.value)
                }}
                required
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="inputPhone">Phone</label>
              <input
                className="form-control"
                id="inputPhone"
                aria-label="inputPhone"
                name="phone"
                value={phone}
                onChange={(e) => {
                  // console.log(e.currentTarget.value)
                  setPhone(e.currentTarget.value)
                }}
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="inputMessage">Message</label>
              <textarea
                className="form-control"
                rows={4}
                id="inputMessage"
                aria-label="inputMessage"
                name="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.currentTarget.value)
                }}
              ></textarea>
            </div>
            <Button></Button>
          </form>}
          <Info></Info>
        </section>
        <section style={{ marginBottom: '16rem' }}>
          <article>
          </article>
        </section>
      </Container>
    </div>
  </Layout>
}
